.Content {
  strong {
    color:#0d348d;
  }
  width: 90%;
  scroll-margin-top: 8rem;
  // background: rgb(238, 242, 255);
  // background: hsl(210, 100%, 97%);
  // text-align: justify;
  // box-shadow: 0 0 6px rgba(31, 39, 73, 0.3);
  font-size: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    font-size: 2.3rem;
  }
  h2 {
    font-weight: 600;
  }
  h1,
  h2 {
    color: #0d348d;
  }
  margin: auto;
  margin-bottom: 3rem;
  // margin-top: rem;
  max-width: 58rem;

  .card-main {
    align-self: flex-start;
    p {
      font-size: 1.2rem;
    }
    // margin: 3rem;
    // padding: 0 3rem;
  }
  .card {
    width: 100%;

    background: white;
    margin: 1rem 0;
    padding: 0.5rem 2rem;
    p {
      font-size: 1.2rem;
    }
  }

  .split {
    @media (max-width:835px) {
      flex-direction: column;
      & > div {
        width: 100% !important;
      }
    }
    display: flex;
    align-items: center;
    gap: 1rem;
    & > div {
      width: 50%;
    }
    ul {

      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      list-style: none;
      padding: 0;
      .card {
        h2 {
          font-size: 1.25rem;
          font-weight: 500;
        }
        padding: 0rem 2rem;
        margin: 0;
        margin-top: 0.7rem;
      }
    }
  }

  .box-shadow {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.1);
    border: 1px solid rgb(13, 52, 141);
  }
  img {
    object-fit: cover;
  }
  a {
    color:#27bde8;
  }
}
