.HeroCard {
  position: absolute;
  box-sizing: border-box;
  width: 100vw;
  max-width: 37.5rem;
  background: white;
  padding: 3rem;
  box-shadow: 4px 4px 6px 2px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  text-align: center;

  h1 {
    // text-align: left;
    color: #0d348d;
    margin: 0;
    font-size: 2.3rem;
  }
  p {
    // text-align: left;
    font-size: 1.2rem;
    line-height: 30px;
  }
  button {
    cursor: pointer;
    margin-top: 1rem;
    font-size: 1.3rem;
    font-weight: bold;
    background: #0d348d;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 1.2rem;
    transition: 0.3s ease all;
    &:hover {
      background: #27bde8;
      //   filter: brightness(200%);
    }
  }

  @media (max-width: 1200px) {
    box-shadow: none;
    margin-top: 2rem;
    position: static;
    max-width: none;
    h1 {
      font-size: 2rem;
    }
    p {
      font-size: 1rem;
    }
  }
}
