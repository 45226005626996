.Banner {
  overflow: visible;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 1.2rem;

  .image {
    background-size: cover;
    background-position: top;
    height: 700px;
    width: 100%;
    border-bottom: 20px solid #0d348d;
  }

  @media (max-width: 1200px) {
      margin-top: 4rem;
    .image {
      //   background-size: contain;
      //   background-repeat: no-repeat;
      border-top: 20px solid #0d348d;
      background-position: center 20%;
      border-bottom: none;
      //   width: 100%;
      height: 100%;
      min-height: 200px;
    //   max-height: 30%;
      max-height: 200px;
    }
  }
}
