.Brand {
  cursor: pointer;
  border: 5px solid #216bb5;
  display: inline-block;
  margin: 1rem;
  transition: 0.3s ease border;
  h1 {
    color: #216bb5;
    font-weight: 800;
    font-size: 1.3rem;
    margin: 0.5rem;
    transition: 0.3s ease color;
  }
  &:hover {
    border: 5px solid #27bde8;
    h1 {
      color: #27bde8;
    }
  }

  @media (max-width: 1200px) {
    h1 {
      font-size: 1rem;
    }
  }
}
