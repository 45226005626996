@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");
/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap'); */
body,
html,
.App,
#root {
  height: 100%;
}
@media (max-width: 1200px) {
  body,
  html,
  .App,
  #root {
    height: auto;
  }
}
html {
  scroll-behavior: smooth;
}

body {
  // background: hsl(210, 100%, 97%);
  background: hsl(210, 100%, 98%);

  // background: hsl(210, 100%, 95%);
  overflow-x: hidden;
  font-family: sans-serif;
  // font-family: "Montserrat", sans-serif;
  margin: 0;
}

* {
  box-sizing: border-box;
}